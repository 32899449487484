var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-space"},[_c('div',{staticClass:"f2f-schedule-heading mt-4 mb-4"},[_c('div',[_vm._v(_vm._s(_vm.isEdit ? "Edit" : "Add")+" Session")]),_c('div',[_c('b-button',{staticClass:"cancelButton mr-4",attrs:{"variant":"link"},on:{"click":function($event){return _vm.hideSession(false)}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"success","disabled":invalid || _vm.submitted},on:{"click":_vm.handleSubmit}},[(_vm.submitted)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isEdit ? "Save" : "Add")+" ")],1)],1)]),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Session title *")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Add a name to your session")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f2f-input',{attrs:{"placeholder":"Session name","error":errors[0]},model:{value:(_vm.sessionObj.title),callback:function ($$v) {_vm.$set(_vm.sessionObj, "title", $$v)},expression:"sessionObj.title"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v("Session schedule *")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v(" Date and time that the session happening. ")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"datepicker",attrs:{"type":"date","editable":false,"value-type":"DD/MM/YY","placeholder":"DD/MM/YY","disabled-date":_vm.disabledDate},model:{value:(_vm.sessionObj.date_at),callback:function ($$v) {_vm.$set(_vm.sessionObj, "date_at", $$v)},expression:"sessionObj.date_at"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"datepicker",attrs:{"type":"time","range":"","editable":false,"show-second":false,"format":"hh:mm A","placeholder":"09:00 AM - 05:00 PM","time-picker-options":{
                    start: '09:00',
                    step: '00:15',
                    end: '17:00',
                    format: 'hh:mm A'
                  }},model:{value:(_vm.timeRange),callback:function ($$v) {_vm.timeRange=$$v},expression:"timeRange"}}),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"f2f-schedule-text"},[_vm._v(" Select instructor teaching the session * ")]),_c('div',{staticClass:"f2f-schedule-span"},[_vm._v("Select from the list")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Instructor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f2f-select',{attrs:{"options":_vm.availableInstructor,"disabled":!_vm.isDateTimeSelected(),"placeholder":"Instructor"},model:{value:(_vm.sessionObj.instructor),callback:function ($$v) {_vm.$set(_vm.sessionObj, "instructor", $$v)},expression:"sessionObj.instructor"}}),(_vm.sessionObj.instructor)?_c('div',{staticClass:"remove-button",on:{"click":_vm.removeInstructor}},[_vm._v(" REMOVE ")]):_vm._e(),(_vm.noAvailableInstructor)?_c('div',{staticClass:"no-available-instructor"},[_c('img',{attrs:{"src":require("@/assets/wrappers/inclination.svg")}}),_vm._v(" There are no instructores available for the selected date and time. ")]):_vm._e(),_c('span',{staticClass:"f2f-validation-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)]}}])}),_c('f2f-modal',{attrs:{"display":_vm.onError,"type":"danger","title":'Unable to ' + (_vm.isEdit ? 'Edit' : 'Add'),"body":_vm.error},on:{"hide":function($event){_vm.onError = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }