<template>
  <b-form>
    <ValidationObserver v-slot="{ invalid }">
      <div class="form-space">
        <div class="f2f-schedule-heading mt-4 mb-4">
          <div>{{ isEdit ? "Edit" : "Add" }} Session</div>
          <div>
            <b-button
              variant="link"
              class="cancelButton mr-4"
              @click="hideSession(false)"
              >Cancel</b-button
            >
            <b-button
              variant="success"
              :disabled="invalid || submitted"
              @click="handleSubmit"
            >
              <b-spinner v-if="submitted" small type="grow"></b-spinner>
              {{ isEdit ? "Save" : "Add" }}
            </b-button>
          </div>
        </div>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Session title *</div>
            <div class="f2f-schedule-span">Add a name to your session</div>
          </b-col>
          <b-col cols="6">
            <ValidationProvider
              name="Title"
              rules="required"
              v-slot="{ errors }"
            >
              <f2f-input
                v-model="sessionObj.title"
                placeholder="Session name"
                :error="errors[0]"
              />
              <span class="f2f-validation-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">Session schedule *</div>
            <div class="f2f-schedule-span">
              Date and time that the session happening.
            </div>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col cols="6">
                <ValidationProvider
                  name="Date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <date-picker
                    class="datepicker"
                    v-model="sessionObj.date_at"
                    type="date"
                    :editable="false"
                    value-type="DD/MM/YY"
                    placeholder="DD/MM/YY"
                    :disabled-date="disabledDate"
                  ></date-picker>
                  <span class="f2f-validation-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-col>
              <b-col cols="6">
                <ValidationProvider
                  name="Time"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <date-picker
                    class="datepicker"
                    v-model="timeRange"
                    type="time"
                    range
                    :editable="false"
                    :show-second="false"
                    format="hh:mm A"
                    placeholder="09:00 AM - 05:00 PM"
                    :time-picker-options="{
                      start: '09:00',
                      step: '00:15',
                      end: '17:00',
                      format: 'hh:mm A'
                    }"
                  ></date-picker>
                  <span class="f2f-validation-text">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col cols="6">
            <div class="f2f-schedule-text">
              Select instructor teaching the session *
            </div>
            <div class="f2f-schedule-span">Select from the list</div>
          </b-col>
          <b-col cols="6">
            <ValidationProvider
              name="Instructor"
              rules="required"
              v-slot="{ errors }"
            >
              <f2f-select
                v-model="sessionObj.instructor"
                :options="availableInstructor"
                :disabled="!isDateTimeSelected()"
                placeholder="Instructor"
              />
              <div
                v-if="sessionObj.instructor"
                class="remove-button"
                @click="removeInstructor"
              >
                REMOVE
              </div>
              <div v-if="noAvailableInstructor" class="no-available-instructor">
                <img src="@/assets/wrappers/inclination.svg" />
                There are no instructores available for the selected date and
                time.
              </div>
              <span class="f2f-validation-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
        </b-row>
      </div>
    </ValidationObserver>

    <f2f-modal
      :display="onError"
      @hide="onError = false"
      type="danger"
      :title="'Unable to ' + (isEdit ? 'Edit' : 'Add')"
      :body="error"
    />
  </b-form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { required, max, digits } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { mapGetters } from "vuex";
import * as moment from "moment";

extend("required", required);
extend("max", max);
extend("digits", digits);
const timeFormat = "HH:mm:ss";

export default {
  props: {
    classId: {
      type: Number,
      default: -1
    },
    sessionId: {
      type: Number,
      default: -1
    }
  },
  components: { DatePicker },
  data() {
    return {
      submitted: false,
      error: "",
      onError: false,
      timeRange: [],
      sessionObj: {
        title: "",
        instructor: null,
        date_at: ""
      },
      disabledBefore: new Date(),
      disabledAfter: new Date(),
      classStartTime: new Date(),
      classEndTime: new Date(),
      existingInstructor: null
    };
  },

  computed: {
    ...mapGetters(["getInstructor", "getLoadingInstructor"]),
    isEdit() {
      return this.sessionId != -1;
    },
    availableInstructor() {
      const list = this.getInstructor || [];
      if (this.existingInstructor) {
        list.push({
          value: this.existingInstructor?.id,
          text:
            this.existingInstructor.first_name +
            " " +
            this.existingInstructor.last_name
        });
      }
      return list;
    },
    noAvailableInstructor() {
      return (
        (this.availableInstructor || []).length == 0 &&
        this.isDateTimeSelected() &&
        !this.getLoadingInstructor
      );
    }
  },

  watch: {
    ["sessionObj.date_at"]: function () {
      this.refreshInstructor();
    },
    ["timeRange"]: function () {
      this.refreshInstructor();
    }
  },
  async mounted() {
    this.setAllowedDates();
    if (this.isEdit) {
      this.setSessionDetails();
    } else {
      this.$store.commit("SET_INSTRUCTOR_DETAIL", []);
    }
  },

  methods: {
    isDateTimeSelected() {
      return this.sessionObj.date_at && this.timeRange[0] && this.timeRange[1];
    },
    removeInstructor() {
      this.sessionObj.instructor = null;
    },
    refreshInstructor() {
      if (this.isDateTimeSelected()) {
        const date_at = moment(this.sessionObj.date_at, "DD/MM/YY").format(
          "YYYY-MM-DD"
        );
        const start_time = this.formatDateByPattern(this.timeRange[0], "HH:mm");
        const end_time = this.formatDateByPattern(this.timeRange[1], "HH:mm");
        this.$store.dispatch("getInstructor", {
          course_id: this.$route.params.id,
          date: date_at,
          start_time,
          end_time
        });
      } else {
        this.sessionObj.instructor = null;
      }
    },
    disabledDate(date) {
      try {
        return !(
          this.disabledBefore &&
          this.disabledAfter &&
          date.setHours(0, 0, 0, 0) >=
            this.disabledBefore.setHours(0, 0, 0, 0) &&
          date.setHours(0, 0, 0, 0) <= this.disabledAfter.setHours(0, 0, 0, 0)
        );
      } catch (error) {
        return true;
      }
    },
    async setAllowedDates() {
      const classResponse = await this.$store.dispatch("getClassDetails", {
        courseId: this.$route.params.id,
        classId: this.classId
      });
      if (classResponse && classResponse.data) {
        const info = classResponse.data;
        this.disabledBefore = new Date(info.start_date_at);
        this.disabledAfter = new Date(info.end_date_at);
        this.classStartTime = moment(info.start_time_at, timeFormat).toDate();
        this.classEndTime = moment(info.end_time_at, timeFormat).toDate();
      }
    },
    async setSessionDetails() {
      const sessionResponse = await this.$store.dispatch("getSessionDetails", {
        courseId: this.$route.params.id,
        classId: this.classId,
        sessionId: this.sessionId
      });
      if (sessionResponse && sessionResponse.data) {
        const info = sessionResponse.data;
        this.sessionObj = {
          ...info,
          instructor: info.instructor?.id?.toString(),
          date_at: this.formatDateByPattern(info.date_at, "DD/MM/YY")
        };
        this.timeRange = [
          moment(info.start_time_at, timeFormat).toDate(),
          moment(info.end_time_at, timeFormat).toDate()
        ];
        this.existingInstructor = info.instructor;
        const date_at = this.formatDateByPattern(info.date_at, "YYYY-MM-DD");
        const start_time = moment(info.start_time_at, timeFormat).format(
          "HH:mm"
        );
        const end_time = moment(info.end_time_at, timeFormat).format("HH:mm");
        this.$store.dispatch("getInstructor", {
          course_id: this.$route.params.id,
          date: date_at,
          start_time,
          end_time
        });
      }
    },
    async handleSubmit() {
      if (
        this.timeRange[0] >= this.classStartTime &&
        this.timeRange[1] <= this.classEndTime
      ) {
        const session = {
          title: this.sessionObj.title,
          start_time_at: this.formatDateByPattern(
            this.timeRange[0],
            timeFormat
          ),
          end_time_at: this.formatDateByPattern(this.timeRange[1], timeFormat),
          date_at: this.parseAndFormatDate(
            this.sessionObj.date_at,
            "DD/MM/YY",
            "YYYY-MM-DD"
          ),
          instructor: this.sessionObj.instructor
        };
        const toPost = {
          courseId: this.$route.params.id,
          classId: this.classId,
          session
        };
        let actionName = "addSession";
        let message = "New session created successfully!";
        if (this.isEdit) {
          toPost.sessionId = this.sessionId;
          actionName = "updateSession";
          message = "Session edited successfully!";
        }
        this.submitted = true;
        try {
          await this.$store.dispatch(actionName, toPost);
          this.hideSession(true);
          this.$store.dispatch("showNotification", { message });
        } catch (err) {
          if (err?.response?.data) {
            const errorKeys = Object.keys(err?.response?.data) || [];
            let erroMessage = "";
            const operationType = this.isEdit
              ? "edit a session"
              : "add a new session";
            if (
              errorKeys.indexOf("start_time_at") >= 0 &&
              errorKeys.indexOf("title") >= 0
            ) {
              erroMessage = `You already have a session at this time and with the same name. Please edit those in order to ${operationType}`;
            } else if (errorKeys.indexOf("start_time_at") >= 0) {
              erroMessage = `You already have a session at this time. Please edit those in order to ${operationType}`;
            } else if (errorKeys.indexOf("title") >= 0) {
              erroMessage = `You already have a session with the same name. Please edit those in order to ${operationType}`;
            }
            if (erroMessage) {
              this.error = erroMessage;
              this.onError = true;
            }
          }
        }
        this.submitted = false;
      } else {
        this.error = `Session start and end time should be between ${this.formatDateByPattern(
          this.classStartTime,
          timeFormat
        )} and ${this.formatDateByPattern(this.classEndTime, timeFormat)}`;
        this.onError = true;
      }
    },
    hideSession(userAction) {
      this.$emit("hideSession", userAction);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-body {
  padding: 0px;
  // .form-space {
  //   min-width: auto;
  // }
}
.remove-button {
  color: #757575;
  cursor: pointer;
  background-color: #fafafa;
  position: absolute;
  top: 7px;
  right: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.no-available-instructor {
  img {
    height: 14px;
  }
  color: #ff4040;
  font-size: 11px;
  padding: 6px 12px;
}
.form-space {
  max-width: 1280px;
  // min-width: 900px;
  margin: 0 auto;
  .f2f-schedule__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0rem;
    border-bottom: 0.5px solid #e8e8e8;

    .schedule-head__title {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #4c4c4c;
    }

    .schedule-head__section {
      font-weight: bold;
      font-size: 38px;
      line-height: 32px;
      letter-spacing: 0.1px;
      color: #333333;
    }
  }
  .f2f-schedule-container {
    background: #ffffff;
    border: 1px solid #b2b2b2;
    box-sizing: border-box;
    .form-space {
      padding: 1rem;
    }
  }

  .f2f-schedule-heading {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.1px;

    color: #4c4c4c;
  }
  .f2f-schedule-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 24px;
    /* or 150% */

    letter-spacing: 0.1px;

    /* Secondary text */

    color: #52575c;
  }

  .f2f-schedule-span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    letter-spacing: 0.2px;

    /* Default texts in text field */

    color: #757575;
  }

  .f2f-validation-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;
    /* identical to box height, or 164% */

    letter-spacing: 0.2px;

    /* Other/Negative */

    color: #ff4040;
  }

  .f2f-schedule-info {
    .class-info__head {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #25282b;
      margin-bottom: 6px;
    }
    .class-info__text {
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #52575c;
    }
  }
}
.has-success {
  color: green;
}
.datepicker {
  width: 100%;
}
.cancelButton {
  color: #717171;
}
button:disabled,
button[disabled] {
  border-radius: 4px;
  background-color: #717171 !important;
  border-color: #717171 !important;
}
</style>
