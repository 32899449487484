

















































































import { mapGetters } from "vuex";
import Sessions from "./Sessions.vue";
import ClassInfo from "./ClassInfo.vue";
import AddOrEditSession from "./AddOrEditSession.vue";
import Vue from "vue";

export default Vue.extend({
  components: { ClassInfo, Sessions, AddOrEditSession },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          title: "Future",
          isActive: true,
          value: "future",
          noRecordMessage: "Currently, there is no future classes happening!"
        },
        {
          title: "Ongoing",
          isActive: false,
          value: "ongoing",
          noRecordMessage: "Currently, there is no ongoing classes happening!"
        },
        {
          title: "Completed",
          isActive: false,
          value: "completed",
          noRecordMessage:
            "Currently, there is no completed classes happening!!"
        },
        {
          title: "Cancelled",
          isActive: false,
          value: "canceled",
          noRecordMessage: "No cancelled classes found!!"
        }
      ],
      fields: [
        // A column that needs custom formatting
        { key: "full_date", label: "Date" },
        { key: "time", label: "Time" },
        "title",
        "instructor",
        { key: "action", label: "" }
      ],
      currentClassId: -1,
      currentSessionId: -1,
      sessionModal: false,
      confirmDelete: false,
      error: "",
      onError: false
    };
  },
  mounted() {
    this.$store.dispatch("listFilteredClasses", {
      course_id: this.$route.params.id,
      status: this.tabs[0].value
    });
  },
  watch: {
    selectedTab(selected) {
      this.$store.dispatch("listFilteredClasses", {
        course_id: this.$route.params.id,
        status: this.tabs[selected].value
      });
    }
  },
  computed: {
    ...mapGetters(["getFilteredClasses", "isLoadingClasses"]),
    selectedStatus(): any {
      return this.tabs[this.selectedTab].value;
    }
  },
  methods: {
    loadSessionForClass(class_id: any) {
      this.$store.dispatch("getSessionsForClass", {
        course_id: this.$route.params.id,
        class_id
      });
    },
    onSession(class_id: any, session_id: any) {
      this.currentClassId = class_id;
      this.currentSessionId = session_id;
      this.sessionModal = true;
    },
    hideSession(userAction: any) {
      this.sessionModal = false;
      userAction && this.loadSessionForClass(this.currentClassId);
    },
    async deleteSession(classId: any, sessionId: any): Promise<void> {
      try {
        await this.$store.dispatch("deleteSession", {
          courseId: this.$route.params.id,
          classId,
          sessionId
        });
        this.$store.dispatch("showNotification", {
          message: "Session Deleted successfully"
        });
        this.loadSessionForClass(classId);
      } catch (err: any) {
        if (err?.response?.data) {
          this.error = Object.keys(err?.response?.data)
            .map((key) => err?.response?.data[key][0])
            .join(",");
          this.onError = true;
        }
      }
    },
    redirect() {
      this.$router.push({ name: "add" });
    },
    toggle(class_id: any, state: any) {
      state && this.loadSessionForClass(class_id);
    }
  }
});
