<template>
  <div>
    <b-table
      v-if="(items || []).length > 0"
      :items="items"
      borderless
      :fields="fields"
      table-class="session-table"
      responsive
      hover
    >
      <!-- A custom formatted column -->
      <template #cell(full_date)="data">
        <div class="schedule-list__fdate session-date">
          <span class="list-fdate__day">
            {{ data.value.day }}
          </span>
          <span class="list-fdate__date">
            {{ data.value.date }}
          </span>
        </div>
      </template>
      <template #cell(time)="data">
        <div class="row-space">
          <p
            class="schedule-list__time line"
            v-for="session in data.item.sessions"
            :key="session.id"
          >
            {{ session.time.start_time }} - {{ session.time.end_time }}
          </p>
        </div>
      </template>
      <template #cell(title)="data">
        <div class="row-space">
          <p
            class="schedule-list__title line"
            v-for="session in data.item.sessions"
            :key="session.id"
          >
            {{ session.title }}
          </p>
        </div>
      </template>
      <template #cell(instructor)="data">
        <div class="row-space">
          <div
            class="instructor-placeholder line"
            v-for="session in data.item.sessions"
            :key="session.id"
          >
            <p
              class="schedule-list__instructor"
              v-if="!session.instructor && !getIsInstructor"
            >
              <img
                src="@/assets/sessions/info.svg"
                alt="info"
                @click="$emit('onSession', session.id)"
                v-b-popover.hover.right="
                  'In order to perform the class you need to assign an Instructor. Click edit to add one.'
                "
              />
            </p>
            <p class="schedule-list__instructor" v-if="session.instructor">
              {{ session.instructor }}
            </p>
          </div>
        </div>
      </template>
      <template #cell(action)="data">
        <div class="row-space" v-if="selectedStatus != 'canceled'">
          <div
            class="action-placeholder line"
            v-for="session in data.item.sessions"
            :key="session.id"
          >
            <div class="schedule-list__cta show-when-hovered">
              <p class="schedule-list__action" @click="redirect(session.id)">
                Mark Attendance
              </p>
              <img
                v-b-tooltip.hover
                title="Edit Session"
                :src="require('@/assets/wrappers/' + editIcon + '.svg')"
                @click="$emit('onSession', session.id)"
                @mouseover="editIcon = 'pencil-hover'"
                @mouseout="editIcon = 'pencil'"
                v-if="!getIsInstructor && selectedStatus != 'completed'"
              />
              <img
                :src="require('@/assets/wrappers/' + deleteIcon + '.svg')"
                alt=""
                class="ml-3"
                @click="onDelete(session.id)"
                v-b-tooltip.hover
                title="Delete Session"
                @mouseover="deleteIcon = 'bin-hover'"
                @mouseout="deleteIcon = 'bin'"
                v-if="!getIsInstructor && selectedStatus != 'completed'"
              />
            </div>
          </div>
        </div>
      </template>
    </b-table>

    <div v-if="loadingClassSessionsId == classId" class="no-record-holder">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div
      v-if="loadingClassSessionsId == -1 && (items || []).length == 0"
      class="no-record-holder"
    >
      <div>
        <img src="@/assets/wrappers/norecord.svg" />
        <div>No sessions</div>
      </div>
    </div>

    <f2f-modal
      :display="onError"
      @hide="onError = false"
      type="danger"
      :title="title"
      :body="error"
    />

    <f2f-modal
      :display="confirmDelete"
      @hide="onConfirm"
      type="warning"
      title="Delete session?"
      body="Are you sure you want to delete this Session?"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: ["sessions", "classId", "selectedStatus"],
  computed: {
    ...mapGetters(["loadingClassSessionsId", "getIsInstructor"]),
    items() {
      const sessionList = [];
      for (let sc = 0; sc < (this.sessions || []).length; sc++) {
        const s = this.sessions[sc];
        const session_date = moment(new Date(s.date_at));
        const full_date = {
          date: session_date.format("DD"),
          day: session_date.format("ddd")
        };
        const obj = {
          full_date,
          time: {
            start_time: moment(s.start_time_at, "HH:mm:ss").format("hh:mm a"),
            end_time: moment(s.end_time_at, "HH:mm:ss").format("hh:mm a")
          },
          title: s.title,
          instructor: s.instructor
            ? s.instructor?.first_name + " " + s.instructor?.last_name
            : null,
          action: "Mark Attendance",
          id: s.id
        };
        const index = sessionList.findIndex(
          (u) =>
            u.full_date.date == full_date.date &&
            u.full_date.day == full_date.day
        );
        if (index >= 0) {
          sessionList[index].sessions.push(obj);
        } else {
          sessionList.push({
            full_date,
            sessions: [obj]
          });
        }
      }

      return sessionList;
    }
  },
  data() {
    return {
      confirmDelete: false,
      currentSessionId: -1,
      fields: [
        { key: "full_date", label: "Date" },
        { key: "time", label: "Time" },
        { key: "title", label: "Title" },
        { key: "instructor", label: "Instructor" },
        { key: "action", label: "Action" }
      ],
      editIcon: "pencil",
      deleteIcon: "bin"
    };
  },
  methods: {
    onDelete(sessionId) {
      this.confirmDelete = true;
      this.currentSessionId = sessionId;
    },
    onConfirm(userAction) {
      userAction && this.$emit("deleteSession", this.currentSessionId);
      this.confirmDelete = false;
    },
    redirect(sessionId) {
      this.$router.push({
        name: "attendance",
        params: {
          classId: this.classId,
          session_id: sessionId
        },
        status: this.selectedStatus || "future"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.show-when-hovered {
  display: none !important;
}

.action-placeholder:hover .show-when-hovered {
  display: flex !important;
}

.instructor-placeholder {
  // height: 20px;
  justify-content: center;
  display: flex;
}

.instructor-placeholder:hover .show-when-hovered {
  display: flex !important;
}

.session-date {
  min-width: 60px;
}

.no-record-holder {
  height: 200px;
  min-height: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #b2b2b2;
  background: white;

  img {
    width: 60px;
    margin-bottom: 1rem;
  }
}

.row-space {
  display: flex;
  flex-direction: column;
}

.row-space .line {
  padding-bottom: 10px;
  padding-top: 10px;
}

// p.row-space:last-child {
//   margin-bottom: 0px !important;
// }

.action-placeholder {
  // width: 200px;
  // float: right;
  min-width: 200px;
  height: 20px;
  min-height: 36px;
}

.schedule-list {
  .nav-tabs {
    border-bottom: none;
  }

  // Add deep for scoped plugin styles https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors
  ::v-deep .schedule-list__tab {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $nav-primary-color;
    padding: 0.5rem 1rem 1rem 1rem;

    &.active {
      color: #333333;
      border: none;
      border-bottom: 5px solid $nav-primary-color;

      &:hover {
        border-color: $nav-primary-color;
      }
    }

    &:hover {
      border-color: transparent;
    }
  }

  img {
    cursor: pointer;
  }

  ::v-deep .session-table {
    background: #ffffff;
    box-sizing: border-box;
    border-top: 1px solid #b2b2b2;
    width: 100%;
    margin-bottom: 20px;

    &.table-hover {
      tbody {
        tr {
          &:hover {
            background: rgba(196, 196, 196, 0.1);
          }
        }
      }
    }

    thead {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: #a0a4a8;
    }

    td {
      vertical-align: inherit;

      .schedule-list__fdate {
        color: #1176b2;
        border-right: 1px solid #b2b2b2;

        .list-fdate__day {
          display: block;
          margin-bottom: 10px;
          font-size: 12px;
          line-height: 16px;
        }

        .list-fdate__date {
          font-weight: 600;
          font-size: 32px;
          line-height: 24px;
          letter-spacing: 0.1px;
          mix-blend-mode: normal;
        }
      }

      .schedule-list__time {
        margin-bottom: 0;
        color: #52575c;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      .schedule-list__title {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #25282b;
        margin-bottom: 0;
      }

      .schedule-list__instructor {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #a0a4a8;
        margin-bottom: 0px;
      }

      .schedule-list__cta {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        margin-right: 20px;

        .schedule-list__action {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.1px;
          color: #a0a4a8;
          margin: 0 20px 0 0;
        }
      }
    }
  }

  .list-header {
    padding-left: 20px;

    .header-text {
      display: flex;
      justify-content: space-between;

      .header-text__title {
        text-align: left;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1176b2;
        padding-bottom: 20px;
        width: calc(100% - 200px);
        @include text-ellipsis;
      }
    }

    .header-content {
      display: flex;

      p {
        line-height: 16px;
        font-size: 14px;
        color: #52575c;
        margin: 0 4% 0 0;

        &.header-content__active {
          font-weight: 600;
          color: #25282b;
        }
      }
    }
  }

  .b-card-text {
    cursor: pointer;
  }
}
</style>
