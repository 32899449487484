<template>
  <div class="f2f-schedule">
    <f2f-alert
      :message="getNotification.message"
      :display="getNotification.display"
      @hide="hideNotification"
    />
    <div class="f2f-schedule__container">
      <b-row class="m-0">
        <b-col cols="12">
          <div class="f2f-schedule__head">
            <div>
              <div class="schedule-head__title">Classes</div>
              <div class="schedule-head__section">Schedule</div>
            </div>
            <f2f-button
              v-if="!getIsInstructor"
              text="+ New Class"
              variant="success"
              @click="redirect"
            />
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4 m-0">
        <b-col cols="9">
          <div v-if="!getLoadingAllClasses">
            <EmptyClasses
              message="You have not added any classes yet."
              :canCreateClass="'true'"
              v-if="getAllClasses.length == 0"
            />
            <ScheduleList v-else />
          </div>
          <div class="loader" v-if="getLoadingAllClasses">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-col>

        <b-col cols="3" class="f2f-schedule-info">
          <div class="class-info__head">Classes</div>
          <div class="class-info__text">
            You can create a class to schedule a onsite event enroll and manage
            instructor's and students.
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ScheduleList from "@/components/classes/schedule/List.vue";
import EmptyClasses from "@/components/classes/Empty.vue";
import { mapGetters } from "vuex";
import Vue from "vue";

export default Vue.extend({
  components: { ScheduleList, EmptyClasses },
  data() {
    return {
      displayAlert: false
    };
  },
  computed: {
    ...mapGetters([
      "getAllClasses",
      "getNotification",
      "getLoadingAllClasses",
      "getIsInstructor"
    ])
  },
  mounted() {
    this.$store.dispatch("listAllClasses", {
      course_id: this.$route.params.id
    });
    this.$store.dispatch("getCourseDetails", this.$route.params.id);
  },
  methods: {
    redirect() {
      this.$router.push({ name: "add" });
    },
    hideNotification() {
      this.$store.dispatch("hideNotification");
    }
  }
});
</script>

<style lang="scss" scoped>
.f2f-schedule {
  .loader {
    margin-top: 20vh;
    text-align: center;
  }
  .f2f-schedule__container {
    max-width: 1280px;
    min-width: 900px;
    margin: 0 auto;
    .f2f-schedule__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0rem;
      border-bottom: 0.5px solid #e8e8e8;

      .schedule-head__title {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #4c4c4c;
      }

      .schedule-head__section {
        font-weight: bold;
        font-size: 38px;
        line-height: 32px;
        letter-spacing: 0.1px;
        color: #333333;
      }
    }

    .f2f-schedule-info {
      .class-info__head {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #25282b;
        margin-bottom: 6px;
      }
      .class-info__text {
        font-family: Roboto;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575c;
      }
    }
  }
}
</style>
