<template>
  <div class="list-header">
    <div class="header-text">
      <div
        v-b-tooltip.hover
        :title="classInfo.title"
        class="header-text__title"
      >
        {{ classInfo.title }}
      </div>
      <div v-if="selectedStatus != 'canceled' && selectedStatus != 'completed'">
        <b-button
          :id="'popover-session-' + classInfo.id"
          class="b-transparent mr-2"
          v-if="!getIsInstructor"
        >
          <img src="@/assets/wrappers/plus.svg" alt="plus" />
        </b-button>

        <b-popover
          :target="'popover-session-' + classInfo.id"
          triggers="focus"
          placement="bottom"
        >
          <div class="edit-option">
            <div @click="$emit('onSession', -1)">Add session</div>
          </div>
        </b-popover>

        <b-button
          :id="'popover-target-' + classInfo.id"
          class="b-transparent"
          v-if="!getIsInstructor"
        >
          <img src="@/assets/wrappers/dots.svg" alt="dots" />
        </b-button>

        <b-popover
          :target="'popover-target-' + classInfo.id"
          triggers="focus"
          placement="bottom"
        >
          <div class="edit-option">
            <div @click="editClass(classInfo.id)">Edit</div>
            <div class="separator"></div>
            <div @click="cancelClass(classInfo.id)" @hide="onCancel">
              Cancel
            </div>
            <div class="separator"></div>
            <div @click="deleteClass(classInfo.id)" @hide="onDeleteConfirm">
              Delete
            </div>
          </div>
        </b-popover>
      </div>
    </div>

    <div class="header-content">
      <p class="header-content__active" style="width: 30%">
        {{ getFormatDuration(classInfo) }}
      </p>
      <p style="word-break: break-all; width: 50%">
        {{ returnEmptyOrName(classInfo.facility) }},
        {{ returnEmptyOrName(classInfo.room) }}
      </p>
      <p style="width: 20%">
        {{ returnEmptyOrName(classInfo.location) }},
        {{ returnEmptyOrName(classInfo.country) }}
      </p>
    </div>
    <div class="header-content pt-3">
      <p class="header-content__active mr-2">Class Coordinator:</p>
      <p>
        {{ getCoordinatorName(classInfo.coordinator) }}
      </p>
    </div>
    <f2f-modal
      :display="confirmDelete"
      @hide="onDeleteConfirm"
      type="warning"
      title="Delete class?"
      body="Are you sure you want to delete this Class?"
    />

    <f2f-modal
      :display="confirmCancel"
      @hide="onCancel"
      type="warning"
      title="Cancel class?"
      body="Are you sure you want to cancel this Class? "
    />

    <b-modal v-model="unableCancel" hide-footer hide-header centered>
      <CancelModal
        :classId="classInfo.id"
        @error="onCancelError"
        @hideReason="hideReason"
        @reload="reloadUI"
      />
    </b-modal>

    <b-modal
      v-model="editClassModal"
      hide-footer
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
    >
      <div class="edit-class">
        <AddOrEditClass :classId="classInfo.id" @onEdit="onEdit" />
      </div>
    </b-modal>

    <f2f-modal
      @hide="
        isCancelError = false;
        isDeleteError = false;
      "
      :display="isCancelError || isDeleteError"
      type="danger"
      :title="isCancelError ? 'Unable to  cancel' : 'Unable to delete'"
      :body="
        isCancelError
          ? 'This class cannot be cancelled as there are users registered to it.'
          : 'This class cannot be deleted as there are users registered to it.'
      "
    />
  </div>
</template>

<script>
import * as moment from "moment";
import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import CancelModal from "./CancelModal.vue";
import AddOrEditClass from "./AddOrEditClass.vue";
import { mapGetters } from "vuex";
extend("required", required);
export default {
  props: ["classInfo", "selectedStatus"],
  data() {
    return {
      status: "",
      isDisabled: false,
      confirmDelete: false,
      confirmCancel: false,
      cancelReason: false,
      unableCancel: false,
      isCancelError: false,
      isDeleteError: false,
      editClassModal: false
    };
  },
  computed: {
    ...mapGetters(["getIsInstructor"])
  },
  components: {
    CancelModal,
    AddOrEditClass
  },
  methods: {
    openSessionModal() {
      this.addSession = true;
    },
    getFormatDuration(classObj) {
      return `${moment(new Date(classObj.start_date_at)).format(
        "MMM Do, YYYY"
      )} to ${moment(new Date(classObj.end_date_at)).format("MMM Do, YYYY")}`;
    },
    editClass(classId) {
      this.currentClassId = classId;
      this.editClassModal = true;
    },
    onEdit(edited) {
      this.editClassModal = false;
      edited && this.reloadUI();
    },
    deleteClass(classId) {
      this.currentClassId = classId;
      this.confirmDelete = true;
    },
    togglePopover(classId) {
      this.currentClassId = classId;
      this.$refs["popover_" + classId.toString()].$emit("open");
    },
    reloadUI() {
      this.$store.dispatch("listAllClasses", {
        course_id: this.$route.params.id,
        isSilentLoad: true
      });
      this.$store.dispatch("listFilteredClasses", {
        course_id: this.$route.params.id,
        status: this.selectedStatus || "future"
      });
    },
    onCancelError() {
      this.isCancelError = true;
    },
    async onDeleteConfirm(userAction) {
      this.confirmDelete = false;
      if (userAction) {
        try {
          await this.$store.dispatch("deleteClass", {
            courseId: this.$route.params.id,
            classId: this.currentClassId
          });
          this.$store.dispatch("showNotification", {
            message: "Class deleted successfully!"
          });
          this.reloadUI();
        } catch (err) {
          this.isDeleteError = true;
          console.log(err);
        }
      }
    },
    cancelClass(classId) {
      this.currentClassId = classId;
      this.confirmCancel = true;
    },
    async onCancel(userAction) {
      if (userAction) {
        try {
          this.confirmCancel = false;
          this.unableCancel = true;
        } catch {
          this.unableCancel = false;
        }
      }
      this.confirmCancel = false;
    },

    hideReason() {
      this.unableCancel = false;
    },
    getCoordinatorName(coordinator) {
      return coordinator
        ? `${coordinator.first_name} ${coordinator.last_name}`
        : "";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-body {
  padding: 0px;
  .form-space {
    min-width: auto;
  }
}
::v-deep .btn-warning {
  color: white;
}

.edit-class {
  padding: 1.5rem;
}
.schedule-list {
  .nav-tabs {
    border-bottom: none;
  }
  // Add deep for scoped plugin styles https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors
  ::v-deep .schedule-list__tab {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $nav-primary-color;
    padding: 0.5rem 1rem 1rem 1rem;
    &.active {
      color: #333333;
      border: none;
      border-bottom: 5px solid $nav-primary-color;
      &:hover {
        border-color: $nav-primary-color;
      }
    }
    &:hover {
      border-color: transparent;
    }
  }
  img {
    cursor: pointer;
  }
  ::v-deep .session-table {
    background: #ffffff;
    box-sizing: border-box;
    border-top: 1px solid #b2b2b2;
    width: 100%;
    margin-bottom: 20px;
    &.table-hover {
      tbody {
        tr {
          &:hover {
            background: rgba(196, 196, 196, 0.1);
          }
        }
      }
    }

    thead {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: #a0a4a8;
    }
    td {
      vertical-align: inherit;
      .schedule-list__fdate {
        color: #1176b2;
        border-right: 1px solid #b2b2b2;
        .list-fdate__day {
          display: block;
          margin-bottom: 10px;
          font-size: 12px;
          line-height: 16px;
        }
        .list-fdate__date {
          font-weight: 600;
          font-size: 32px;
          line-height: 24px;
          letter-spacing: 0.1px;
          mix-blend-mode: normal;
        }
      }
      .schedule-list__time {
        margin-bottom: 0;
        color: #52575c;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
      }
      .schedule-list__title {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #25282b;
        margin-bottom: 0;
      }
      .schedule-list__instructor {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #a0a4a8;
      }
      .schedule-list__cta {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        margin-right: 20px;
        .schedule-list__action {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.1px;
          color: #a0a4a8;
          margin: 0 20px 0 0;
        }
      }
    }
  }

  .list-header {
    padding-left: 20px;
    .header-text {
      display: flex;
      justify-content: space-between;
      .header-text__title {
        text-align: left;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1176b2;
        padding-bottom: 20px;
        width: calc(100% - 200px);
        @include text-ellipsis;
      }
    }

    .header-content {
      display: flex;
      p {
        line-height: 16px;
        font-size: 14px;
        color: #52575c;
        margin: 0 4% 0 0;
        text-align: left;
        &.header-content__active {
          font-weight: 600;
          color: #25282b;
        }
      }
    }
  }
  .b-card-text {
    cursor: pointer;
  }
}

.edit-option {
  background: #ffffff;
  border-radius: 4px;
  border: none;

  .separator {
    border: 0.5px solid #e8e8e8;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 120px;
  }

  div {
    color: #52575c;
    font-size: 16px;
  }

  div:hover {
    color: #0043ac;
    cursor: pointer;
  }
}
.b-transparent {
  background: transparent;
  border: none;
  :focus {
    background: transparent;
  }
}
.btn-secondary:hover {
  background: none;
}
.btn-secondary:focus {
  background: none;
}

.modal-content {
  padding: 1.5rem;
  border: none;
  border-left: 5px solid #ffaa15;

  .title {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #25282b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    img {
      height: 16px;
      cursor: pointer;
    }
  }
  .body {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    margin-bottom: 40px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    .cancel {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #a0a4a8;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .warning {
    border-left: 5px solid #ffaa15;
  }
}
</style>
